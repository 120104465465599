import React from 'react';

import Layout from '../components/layout';

const redirect = function(e) {
	e.preventDefault();
	window.location.replace('../index.html');
	return false;
};

const SecondPage = () => (
	<Layout id="login">
		<form className="loginform" onSubmit={redirect}>
			<fieldset>
				<label>Login:</label>
				<input type="text" />
			</fieldset>
			<fieldset>
				<label>Password:</label>
				<input type="password" />
			</fieldset>
			<input type="submit" value="Submit" />
		</form>
	</Layout>
);

export default SecondPage;
